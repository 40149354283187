
import { useState, useEffect } from 'react';
import { useAppDispatch, } from "../store/storeHooks";
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common/interfaces';
import { ExtendedDataProduct } from '../clients/dataPortalApi';
import { ReactAppEnvironment } from '../common';
import useSegment, { EventProperties } from './useSegment';

export interface DatabricksDatasetsProps {
    dataProductId: string;
    isDataProductAdmin: boolean;
    accessToken: string;
    getDatasets: (dataProductId: string) => DatasetModel[];
    addDataset: (dataProductId: string, dataset: DatasetModel) => void,
    removeDataset: (dataProductId: string, dataset: DatasetModel) => void
    trackEvent?: (eventName: string, eventProperties: EventProperties ) => void;
}

export interface DatasetModel {
    id: string;
    name: string;
    type: string;
}

const getDatabricksManagementApiUrl = () => {
    const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.api.databricks-management.cimpress.io' : 'dev.api.databricks-management.cimpress.io';
    return `https://${baseUrl}`;
};

export const useDatabricksDatasets = (userDetails: UserDetails, dataProduct: ExtendedDataProduct | null, hasDataProductAdminAccess: boolean): {
    databricksDatasetsModuleProps: Partial<DatabricksDatasetsProps> | undefined;
} => {

    const dispatch = useAppDispatch();
    const [databricksDatasetsModuleProps, setDatabricksDatasetsModuleProps] = useState<Partial<DatabricksDatasetsProps>>();
    const segment = useSegment();

    useEffect(() => {
       if(dataProduct == null || userDetails.accessToken == null)
           return;

        const dataProductId = dataProduct.dataProductId;
        const accessToken = userDetails.accessToken || '';
        const apiUrl = getDatabricksManagementApiUrl();
        const isDataProductAdmin = hasDataProductAdminAccess;
        /* eslint-disable  @typescript-eslint/no-unused-vars  */
        const props = {
            dataProductId,
            accessToken,
            isDataProductAdmin,
            databricksManagementApiUrl: apiUrl,
            getDatasets : (_dataProductId: string) => { return []; },
            addDataset(_dataProductId: string, _dataset: DatasetModel) : void { return; },
            removeDataset(_dataProductId: string, _dataset: DatasetModel) : void { return; },
            trackEvent: segment.trackEvent
          };
          setDatabricksDatasetsModuleProps(props);
    }
    , [userDetails.accessToken, dataProduct, dispatch, segment.trackEvent, hasDataProductAdminAccess]);
    /* eslint-enable   @typescript-eslint/no-unused-vars  */

    return { databricksDatasetsModuleProps };
};
