import React, { useEffect, useMemo } from "react";
import {
    Interfaces,
} from "@cimpress-technology/data-portal-core";
import { decodeDataDiscoveryId } from "@cimpress-technology/data-portal-core/lib/clients/dataDiscoveryMetadataApi";
import { DataContractModule } from "../../../dataContracts/DataContractsWrapper";
import { useHistory } from "react-router-dom";
import { ShareInfo } from "../../../../interfaces/dataShares";
import { Card } from "@cimpress/react-components";
import { AuthContext } from "../../../../context/authContext";
import { PdwDatasetGetCollectionItem, PdwDatasetGetItem, isPdwDatasetGetItem } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/dataProductResources";
import { useAppDispatch, useAppSelector } from "../../../../store/storeHooks";
import { getPdwDataset } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { Spinner } from "@cimpress/react-components/lib/shapes";

const buildDataShareContractInfo = (
    dataset: Props["metadata"],
    shareInfo: ShareInfo
) => {
    const metadata = Object.assign({}, dataset, {
        id: shareInfo.resourceId,
        cluster: shareInfo.account,
        database: shareInfo.database,
        schema: shareInfo.schema,
        name: shareInfo.name,
    });
    const selectedColumns = shareInfo.embedded?.selectedFields?.map((o) =>
        o.column.toUpperCase()
    );
    metadata.columns = metadata.columns.filter(
        (o) =>
            !selectedColumns || selectedColumns.includes(o.name.toUpperCase())
    );
    return metadata;
};

const buildSharesInfo = (
    consumableDatasets: PdwDatasetGetItem['properties']['consumableDatasets']
) => {
    const result: ShareInfo[] = (
        consumableDatasets || []
    ).map((o) => {
        const {
            pdw: account,
            database,
            schema,
            dataset: datasetOrSecureView,
        } = decodeDataDiscoveryId(o.resourceId);
        return {
            resourceId: o.resourceId,
            embedded: o._embedded,
            account: account,
            database: database,
            schema: schema,
            name: datasetOrSecureView,
        };
    });
    return result;
};

type Props = {
    accessToken: string;
    dataProductId: string;
    metadata: Interfaces.DataDiscovery.DataDiscoveryDatasetMetadata;
    dataset: PdwDatasetGetItem | PdwDatasetGetCollectionItem;
};

const ConsumableDatasets = (props: Props): JSX.Element => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const authContext = React.useContext(AuthContext);
    const { datasetsStatus } = useAppSelector((state) => state.resourcesPdwDatasets);
    const dataShares: ShareInfo[] = useMemo(
        () => isPdwDatasetGetItem(props.dataset) ? buildSharesInfo(props.dataset.properties.consumableDatasets) : [],
        [props.dataset]
    );

    useEffect(() => {
        if (!isPdwDatasetGetItem(props.dataset)) {
            dispatch(
                getPdwDataset({
                    accessToken: props.accessToken,
                    dataProductId: props.dataProductId,
                    resourceId: props.dataset.resourceId
                })
            );
        }
    }, [props.dataProductId, props.dataset, props.accessToken, props.dataset.resourceId, dispatch]);

    const contractCards = useMemo(() => {
        return dataShares.map((share) => (
            <div
                key={share.resourceId}
                className="row"
                style={{ marginBottom: 24 }}
            >
                <Card style={{ padding: 4 }}>
                    <div className="row" style={{ marginBottom: 12 }}>
                        <div className="col-md-8">
                            <span style={{ fontWeight: 900 }}>
                                {share.resourceId}
                            </span>
                        </div>
                        <div
                            className="col-md-4"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <span
                                className="label label-info"
                                style={{ fontWeight: 600, fontSize: 12 }}
                            >
                                {share.account}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataContractModule
                                dataset={buildDataShareContractInfo(
                                    props.metadata,
                                    share
                                )}
                                dataProductId={props.dataProductId}
                                history={history}
                                authContext={authContext}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        ));
    }, [props.metadata, props.dataProductId, dataShares, authContext, history]);

    const DataSharesList = (
        <div className="row" style={{ marginLeft: 15, marginRight: 15 }}>
            {datasetsStatus[props.dataset.resourceId]?.getById ===
                "loading" && <Spinner size="small" />}
            {datasetsStatus[props.dataset.resourceId]?.getById === "failed" && (
                <p>Failed to load consumable datasets.</p>
            )}
            {["succeeded", "idle"].includes(datasetsStatus[props.dataset.resourceId]?.getById) && (
                <>
                    {contractCards.map((contractCard) => contractCard)}
                    {dataShares.length === 0 && (
                        <p>
                            This dataset is not shared with your <b>account</b>.
                            Request the Data Product Admins to share it.
                        </p>
                    )}
                </>
            )}
        </div>
    );

    return <div>{DataSharesList}</div>;
};

export default ConsumableDatasets;
