import {useState, useEffect} from 'react';
import {appendScript} from "../../utils/common";
import {ReactAppEnvironment} from "../../common";


// Define base URL depending on the environment. If the environment is set to Production, we are connecting to the production server.
// Otherwise, the connection is local.
const getBaseUrl = () => {
    return process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production
        ? 'https://ui.issue-tracker.data.cimpress.io'
        : 'https://ui.stg.issue-tracker.data.cimpress.io';
    // : 'http://localhost:5508';
};

const loadBundleJs = async (accessToken, bundleName = "bundle.js", setLoading, setError) => {
    setLoading(true);
    setError(null);

    if (accessToken) {
        try {
            await appendScript(`${getBaseUrl()}/${bundleName}`, accessToken);
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }
    setLoading(false);
};

export const useIssueTrackerModule = (accessToken: string | undefined, forceLoadNow = false): {
    loadingBundle: boolean;
    loadBundleErrors: Error | null;
    loadBundleJs(): ReturnType<typeof loadBundleJs>;
} => {
    const shouldLoadNow = typeof window.dataportalresources == typeof undefined || typeof window.dataportalresources["IssueTracker"] == typeof undefined || forceLoadNow;
    const [loading, setLoading] = useState(shouldLoadNow);
    const [error, setError] = useState(null as Error | null);

    useEffect(() => {
        if (!(accessToken && shouldLoadNow))
            return;

        loadBundleJs(accessToken, "bundle.js", setLoading, setError).then();
    }, [accessToken, shouldLoadNow]);

    return {
        loadingBundle: loading,
        loadBundleErrors: error,
        loadBundleJs: () => loadBundleJs(accessToken, "bundle.js", setLoading, setError)
    };
};
