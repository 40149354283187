import React from 'react';
import { colors, NavTab, NavTabItem } from '@cimpress/react-components';
import IconArrowCircleLeft from '@cimpress-technology/react-streamline-icons/lib/IconArrowCircleLeft';
import IconArrowCircleRight from '@cimpress-technology/react-streamline-icons/lib/IconArrowCircleRight';
import IconBookOpenBookmark from '@cimpress-technology/react-streamline-icons/lib/IconBookOpenBookmark';
import IconAnalyticsGraph from '@cimpress-technology/react-streamline-icons/lib/IconAnalyticsGraph';
import IconLayers from '@cimpress-technology/react-streamline-icons/lib/IconLayers';
import IconReceiptDollar from '@cimpress-technology/react-streamline-icons/lib/IconReceiptDollar';
import IconDrop from '@cimpress-technology/react-streamline-icons/lib/IconDrop';
import IconLayoutModule from '@cimpress-technology/react-streamline-icons/lib/IconLayoutModule';
import IconMultipleUsers from '@cimpress-technology/react-streamline-icons/lib/IconMultipleUsers';
import IconShareAlt from '@cimpress-technology/react-streamline-icons/lib/IconShareAlt';
import IconUserLock from '@cimpress-technology/react-streamline-icons/lib/IconUserLock';
import IconVectorsAnchorSquareAlt from '@cimpress-technology/react-streamline-icons/lib/IconVectorsAnchorSquareAlt';
import IconWarehousePackages from '@cimpress-technology/react-streamline-icons/lib/IconWarehousePackages';
import IconFileCode from '@cimpress-technology/react-streamline-icons/lib/IconFileCode';
import { OtherSubTabs } from '../../interfaces/tabs';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { useAppSelector } from '../../store/storeHooks';
import { DataProduct} from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { DataIssueCounterModule } from "../issueTracker/IssueTrackerWrapper";
import { usePdwRolesCount } from '../../store/usePdwRolesCount';
import { useLookerCount } from '../../store/useLookerCount';
import { useHistory } from 'react-router-dom';

export enum Tabs {
    DataProductDetails = 'DataProductDetails',
    DataProductAccess = 'DataProductAccess',
    DataIssueTracker = 'DataIssueTracker',
    CostReport = 'CostReport',
    InputPorts = 'InputPorts',
    Resources = 'Resources',
    OutputPorts = 'OutputPorts',
}

const ComponentCount = (props: { count: number | null }) => {
    if (props.count !== null) {
        return <sup style={{ color: colors.shale }}>{props.count}</sup>;
    } else {
        return null;
    }
};

export const DataProductDetailsNav: React.FC<{
    hasDataProductManagePermission: boolean;
    hasDataProductAccess: boolean;
    selectedTab: Tabs;
    selectedSubTab: DataPortalAPI.InputPortType | DataPortalAPI.DataResourceType | DataPortalAPI.OutputPortType | OtherSubTabs | null;
    onSelectedTab: (tab: Tabs, subTab: DataPortalAPI.InputPortType | DataPortalAPI.DataResourceType | DataPortalAPI.OutputPortType | OtherSubTabs | null) => void;
    disabledNav?: boolean;
    // We can directly use store instead of odwdataset hook once we start using redux for pdwDataset
    dataProduct?: DataProduct;
    databricksUnitsCount: number;
}> = (props) => {
    const resourcesPdwDatasetCount = useAppSelector((state) => state.resourcesPdwDatasets.pdwDatasetCollectionState.data?.length || null);
    const pdwDatasetCount = useAppSelector((state) => state.outputPorts.outputPortCollectionState.data?.length || null);
    const history = useHistory();
    const lookerDashboardsCount = useLookerCount();
    const conductorsCount = useAppSelector(state => state.minimalConductors.conductors)?.length || null;
    const riverStreamsCount = useAppSelector((state) => state.riverStreams.riverStreams)?.length || null;
    const inputPortDatasetsCount = useAppSelector((state) => state.inputPortDataset.inputPortDatasets)?.length || null;
    const pdwRolesCount = usePdwRolesCount();

    const inputPorts = [
        { icon: <IconDrop />, name: 'River Streams', itemKey: DataPortalAPI.InputPortType.RiverStream, count: riverStreamsCount },
        { icon: <IconLayoutModule />, name: 'Datasets', itemKey: DataPortalAPI.InputPortType.PDWDataset, count: inputPortDatasetsCount }
    ];

    const resources = [
        { icon: <IconFileCode />, name: 'PDW Datasets', itemKey: OtherSubTabs.resourcesPdwDataset, count: resourcesPdwDatasetCount },
        { icon: <IconVectorsAnchorSquareAlt />, name: 'Airflow', itemKey: DataPortalAPI.DataResourceType.Conductor, count: conductorsCount },
        { icon: <IconLayers />, name: 'Databricks', itemKey: DataPortalAPI.DataResourceType.Databricks, count: props.databricksUnitsCount },
        { icon: <IconLayers />, name: 'Databricks Datasets', itemKey: OtherSubTabs.resourcesDatabricksDatasets, count: null },
        { icon: <IconShareAlt />, name: `Data Sharing`, itemKey: DataPortalAPI.DataResourceType.DataSharing, count: null },
        { icon: <IconUserLock />, name: `PDW Roles`, itemKey: DataPortalAPI.DataResourceType.PDWRole, count: pdwRolesCount },
    ];

    const outputPorts = [
        { icon: <IconLayoutModule />, name: 'Datasets', itemKey: DataPortalAPI.OutputPortType.PDWdataset, count: pdwDatasetCount },
        { icon: <IconAnalyticsGraph />, name: 'Looker HomePage', itemKey: DataPortalAPI.OutputPortType.LookerDashboards, count: lookerDashboardsCount }
    ];

    return <NavTab vertical>
        <NavTabItem disabled={props.disabledNav} active={props.selectedTab === Tabs.DataProductDetails} style={{ marginLeft: -20 }}>
            <button disabled={props.disabledNav} onClick={() => {
                props.onSelectedTab(Tabs.DataProductDetails, null);
            }}>
                <IconBookOpenBookmark />&nbsp;&nbsp;Data Product Details
            </button>
        </NavTabItem>

        {
            props.hasDataProductManagePermission
                ?
                <NavTabItem disabled={props.disabledNav} active={props.selectedTab === Tabs.DataProductAccess} style={{ marginLeft: -20 }}>
                    <button disabled={props.disabledNav} onClick={() => {
                        props.onSelectedTab(Tabs.DataProductAccess, null);
                    }}>
                        <IconMultipleUsers />&nbsp;&nbsp;Access Management
                    </button>
                </NavTabItem>
                : null
        }

        {
            <NavTabItem disabled={props.disabledNav} active={props.selectedTab === Tabs.DataIssueTracker} style={{ marginLeft: -20 }}>
                <button disabled={props.disabledNav} onClick={() => {
                    props.onSelectedTab(Tabs.DataIssueTracker, null);
                }}><DataIssueCounterModule dataProductId={props.dataProduct?.dataProductId} history={history}/></button>
            </NavTabItem>
        }

        {
            props.hasDataProductManagePermission
                ?
                <NavTabItem disabled={props.disabledNav} active={props.selectedTab === Tabs.CostReport} style={{ marginBottom: 16, marginLeft: -20 }}>
                    <button disabled={props.disabledNav} onClick={() => {
                        props.onSelectedTab(Tabs.CostReport, null);
                    }}>
                        <IconReceiptDollar />&nbsp;&nbsp;Cost Report
                    </button>
                </NavTabItem>
                : null
        }

        <hr />

        {
            props.hasDataProductManagePermission
                ? <>
                    <h6 style={{ color: colors.shale }}><IconArrowCircleRight />&nbsp;&nbsp;Input Ports</h6>
                    {
                        inputPorts.map(p => <NavTabItem disabled={props.disabledNav} active={props.selectedSubTab === p.itemKey && props.selectedTab === Tabs.InputPorts} key={p.itemKey}>
                            <button disabled={props.disabledNav} onClick={() => {
                                props.onSelectedTab(Tabs.InputPorts, p.itemKey);
                            }}>
                                {p.icon}&nbsp; &nbsp; {p.name}  <ComponentCount count={p.count} />
                            </button>
                        </NavTabItem>)
                    }
                    <hr style={{ marginTop: 0, marginBottom: 16 }} />
                </>
                : null
        }
        {
            (props.hasDataProductManagePermission || props.hasDataProductAccess)
                ? <>
                    <h6 style={{ color: colors.shale }}><IconWarehousePackages />&nbsp;&nbsp;Resources</h6>
                    {
                        resources.map(p => <NavTabItem disabled={props.disabledNav} active={props.selectedSubTab === p.itemKey && props.selectedTab === Tabs.Resources} key={p.itemKey}>
                            <button disabled={props.disabledNav} onClick={() => {
                                props.onSelectedTab(Tabs.Resources, p.itemKey);
                            }}>
                                {p.icon}&nbsp; &nbsp; {p.name} <ComponentCount count={p.count} />
                            </button>
                        </NavTabItem>)
                    }
                    <hr style={{ marginTop: 0, marginBottom: 16 }} />
                </>
                : null
        }
        <h6 style={{ color: colors.shale }}><IconArrowCircleLeft />&nbsp;&nbsp;Output Ports</h6>
        {
            outputPorts.map(p => <NavTabItem disabled={props.disabledNav} active={props.selectedSubTab === p.itemKey && props.selectedTab === Tabs.OutputPorts} key={p.itemKey}>
                <button disabled={props.disabledNav} onClick={() => {
                    props.onSelectedTab(Tabs.OutputPorts, p.itemKey);
                }}>
                    {p.icon}&nbsp; &nbsp; {p.name} <ComponentCount count={p.count} />
                </button>
            </NavTabItem>)
        }
    </NavTab >;
};