import { useState, useEffect } from 'react';
import { appendScript } from "../../utils/common";
import { AuthenticationProps } from '@cimpress-technology/data-portal-core/lib/hooks/auth';
import { ReactAppEnvironment } from "../../common";


// Define base URL depending on the environment. If the environment is set to Production, we are connecting to the production server.
// Otherwise, the connection is local.
const getBaseUrl = () => {
    return process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production
        ? 'https://ui.contracts.data.cimpress.io'
        : 'https://ui.stg.contracts.data.cimpress.io';
    // : 'http://localhost:5508';
};

const loadBundleJs = async (authContext, bundleName = "bundle.js", setLoading, setError) => {
    setLoading(true);
    setError(null);

    if (authContext) {
        try {
            await appendScript(`${getBaseUrl()}/${bundleName}`, authContext.accessToken);
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }
    setLoading(false);
};

export const useDataContractsModule = (authContext: AuthenticationProps | undefined, forceLoadNow = false): {
    loadingBundle: boolean;
    loadBundleErrors: Error | null;
    loadBundleJs(): ReturnType<typeof loadBundleJs>;
} => {
    const shouldLoadNow = typeof window.dataportalresources == typeof undefined || typeof window.dataportalresources["DataContracts"] == typeof undefined || forceLoadNow;
    const [loading, setLoading] = useState(shouldLoadNow);
    const [error, setError] = useState(null as Error | null);

    useEffect(() => {
        if (!(authContext && shouldLoadNow))
            return;

        loadBundleJs(authContext, "bundle.js", setLoading, setError).then();
    }, [authContext, shouldLoadNow]);

    return {
        loadingBundle: loading,
        loadBundleErrors: error,
        loadBundleJs: () => loadBundleJs(authContext, "bundle.js", setLoading, setError)
    };
};
