export const appendScript = async (scriptUrlToAppend: string, accessToken: string) => {

    const header = new Headers();
    header.append('Authorization', `Bearer ${accessToken}`);

    const response = await fetch(scriptUrlToAppend, {
        method: 'GET',
        headers: header
    });
    const script = document.createElement("script");
    script.type = "module";
    script.innerHTML = String(await response.text());
    document.body.appendChild(script);
};
