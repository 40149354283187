import React, { useRef, useEffect, useCallback } from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common';
import { useDatabricksDatasets } from '../../hooks/useDatabricksDatasets';

interface Props {
    dataProduct: DataPortalAPI.DataProduct,
    userDetails: UserDetails,
    hasDataProductAdminAccess: boolean,
}

export const DatabricksDatasets: React.FC<Props> = (props) => {

    const databricksDatasets = useRef<HTMLInputElement>(null);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const renderFunction = (window as any)?.dataportalresources["databricks-datasets"]?.render;

    const { databricksDatasetsModuleProps } = useDatabricksDatasets(props.userDetails, props.dataProduct, props.hasDataProductAdminAccess);

    const renderFunctionCallback = useCallback( async (domElement) => {
        if(renderFunction != undefined && databricksDatasetsModuleProps != undefined && databricksDatasetsModuleProps?.accessToken)
        {
            await renderFunction(domElement, { ...databricksDatasetsModuleProps });
        }

    }, [databricksDatasetsModuleProps, renderFunction]);

    useEffect(() => {
        if (databricksDatasets?.current != null &&
            databricksDatasets?.current?.childElementCount == 0) {
                renderFunctionCallback(databricksDatasets.current);
        }
    }
        , [databricksDatasets, renderFunctionCallback]);

    return (
        <div ref={databricksDatasets}></div>
    );
};
